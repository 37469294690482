import { LG, LGR, MD, SM, XL, XS, XS_L, XS_M, XS_ML, XXL, XXS } from "constants/sizes";
export const titleFontSizeNumber = {
    [XXS]: 0.6875,
    [XS_M]: 0.875,
    [XS_ML]: 1,
    [XS_L]: 1.125,
    [XS]: 1.375,
    [SM]: 1.75,
    [MD]: 2.25,
    [LG]: 2.75,
    [LGR]: 3.375,
    [XL]: 4,
    [XXL]: 4.5
};
export const titleLineHeights = {
    [XXS]: "32px",
    [XS_M]: "32px",
    [XS_ML]: "32px",
    [XS_L]: "32px",
    [XS]: "32px",
    [SM]: "40px",
    [MD]: "48px",
    [LG]: "60px",
    [LGR]: "68px",
    [XL]: "72px",
    [XXL]: "85px"
};
export const titleFontSizes = {
    [XXS]: `${titleFontSizeNumber[XXS]}rem`,
    [XS_M]: `${titleFontSizeNumber[XS_M]}rem`,
    [XS_ML]: `${titleFontSizeNumber[XS_ML]}rem`,
    [XS_L]: `${titleFontSizeNumber[XS_L]}rem`,
    [XS]: `${titleFontSizeNumber[XS]}rem`,
    [SM]: `${titleFontSizeNumber[SM]}rem`,
    [MD]: `${titleFontSizeNumber[MD]}rem`,
    [LG]: `${titleFontSizeNumber[LG]}rem`,
    [LGR]: `${titleFontSizeNumber[LGR]}rem`,
    [XL]: `${titleFontSizeNumber[XL]}rem`,
    [XXL]: `${titleFontSizeNumber[XXL]}rem` // 72px
};
