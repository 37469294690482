import styled from "@emotion/styled";
export const StyledParagraph = styled.div`
  .paragraph-body {
    font-size: 1.125rem;
    line-height: 32px;
    margin: 0; //overide h3 global definition
    word-break: break-word;

    &.xs {
      font-size: 0.8125rem;
      line-height: 20px;
    }

    &.sm {
      font-size: 0.875rem;
      line-height: 22px;
    }

    &.ms {
      font-size: 1rem;
      line-height: 24px;
    }

    &.md {
      font-size: 1.125rem;
      line-height: 32px;
    }

    &.lg {
      font-size: 1.375rem;
      line-height: 40px;
    }
  }
`;
