import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
export const StyledFullParagraph = styled.div`
  word-break: break-word;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.align-items-left {
    align-items: flex-start;
  }

  &.align-items-center {
    align-items: center;

    .title-wrapper {
      width: 100%;
    }
  }

  &.align-items-right {
    align-items: flex-end;
  }

  &.with-left-line {
    border-width: 2px;
    border-style: solid;
    padding-left: 32px;
  }

  .full-paragraph-topic-image-and-text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .full-paragraph-topic-image-wrapper {
    margin-bottom: 40px;
  }

  .full-paragraph-topic {
    font-size: 0.875rem;
    margin-bottom: 16px;
  }

  .title-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .paragraph-body-wrapper {
  }

  .icons-wrapper {
    margin-top: 40px;
    margin-bottom: 64px;
  }

  .bullets-wrapper {
    margin-top: 32px;
    display: flex;
    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      &.no-mobile-margin {
        margin-top: 0;
      }
    }

    &.left {
      justify-content: flex-start;

      .bullets {
        justify-content: flex-start;
      }
    }

    &.center {
      justify-content: center;

      .bullets {
        justify-content: center;
      }
    }

    &.right {
      justify-content: flex-end;

      .bullets {
        justify-content: flex-end;
      }
    }
  }

  .full-paragraph-topic-image-wrapper {
    &.left {
      :global(.picture-component) {
        justify-content: left;
      }
    }

    &.center {
      :global(.picture-component) {
        justify-content: center;
      }
    }

    &.right {
      :global(.picture-component) {
        justify-content: right;
      }
    }
  }

  .button-wrapper {
    margin-top: 32px;
    display: flex;
      
    width: ${({ buttonWidth })=>buttonWidth || ""};
      .button {
        width: ${({ buttonWidth })=>buttonWidth || ""};
      }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }
`;
