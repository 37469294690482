import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
export const StyledIcons = styled.div`
  display: flex;
  .icon-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
    img {
      max-height: 32px;
    }
    .icon-texts-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .title {
        .monday-markup-language-component {
          font-size: 1.463rem;
          line-height: 26px;
          font-weight: 500;
        }
      }
      .subtitle {
        .monday-markup-language-component {
          font-size: 1rem;
          line-height: 20px;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    display: flex;
    .icon-wrapper {
      flex-direction: column;
      align-items: center;
      padding-bottom: 35px;
      .icon-texts-wrapper {
        align-items: center;
        text-align: center;
        .title {
          .monday-markup-language-component {
            font-size: 1.125rem;
          }
        }
        .subtitle {
          .monday-markup-language-component {
            font-size: 0.8125rem;
          }
        }
      }
    }
  }
`;
