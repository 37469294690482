export default {
    bulletsDirection: "row",
    bulletTextSize: "0.875rem",
    bullets: [
        {
            bulletText: "Support ticket management"
        },
        {
            bulletText: "Creative requests"
        }
    ]
};
